import React, { useEffect, useState, Fragment } from "react"
import Header from "@Components/Header/Header"
import Footer from "@Components/Footer/Footer"
import MarketingServices404 from "@Components/Static/MarketingServices404/MarketingServices404"
import Layout from "../Components/Layout/layout"
import { Link, navigate } from "gatsby"
import axios from "axios"
// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

const getPropurl = async (handler, crmid) => {
  let result = []
  //instead of hit REST we use json format to get only required fields. we dont import apollo since this call from another Hook
  var data = JSON.stringify({
    query:
      `query($crm_id:[String]) { properties(sort: "updatedAt:desc", where: { crm_id: $crm_id}) { id, slug, search_type, department, crm_id } }`,
    variables: { crm_id: [crmid] },
  })
  // console.log("🚀 ~ file: 404.js ~ line 17 ~ getPropurl ~ data", data)
  var config = {
    method: "post",
    url: `${process.env.GATSBY_STRAPI_SRC}/graphql`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
    data: data,
  }
  const res = await axios(config)
  result[0] = JSON.stringify(res.data)
  handler(result) //update state var
}

const getnewhomesPropurl = async (handler, crmid) => {
  let result = []
  //instead of hit REST we use json format to get only required fields. we dont import apollo since this call from another Hook
  var data = JSON.stringify({
    query:
      "query($crm_id:[String]) { newDevelopments(where: { crm_id: $crm_id}) { id, slug, crm_id } }",
    variables: { crm_id: [crmid] },
  })
  // console.log("🚀 ~ file: 404.js ~ line 17 ~ getPropurl ~ data", data)
  var config = {
    method: "post",
    url: `${process.env.GATSBY_STRAPI_SRC}/graphql`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
    data: data,
  }
  const res = await axios(config)
  result[0] = JSON.stringify(res.data)
  handler(result) //update state var
}

// markup
const NotFoundPage = (props) => {
  const [showtemplate, setShowtemplate] = React.useState(false)
  const [propdata, setPropdata] = React.useState("") // false

  const [propnewdata, setNewPropdata] = React.useState("") // false

  React.useEffect(() => {
    //we doing here as fallback, if any case build failed or not triggered then the below will be useful; when build happens redirects will happen a t netlify itself, so dont need to do CSR
    let regex = "\[a-zA-Z]{3}[0-9]{6}"; //you can modify the preg as per proj feed
    if (props?.location?.pathname) {
      let crm_id = (props?.location?.pathname).match(regex)
      //mke sure state value is empty and we have proper url to proceed
      if (typeof crm_id != "undefined" && crm_id != null && crm_id.length > 0 && !propdata) {
        getPropurl(setPropdata, crm_id[0]) // call the function to fetch data from strapi
      } else if (propdata) {
        let property_data = JSON.parse(propdata)
        let property_arr = property_data.data.properties
        if (property_arr.length > 0) {
          let property = property_arr[0]
          if (property.department == "residential") {
            if (property.search_type == "sales") {
              navigate(`/property-for-sale/${property.slug}-${property.id}`, {
                replace: true,
              })
            } else if (property.search_type == "lettings") {
              navigate(`/property-to-rent/${property.slug}-${property.id}`, {
                replace: true,
              })
            }
          }
          else if (property.department == "NH") {
            if (property.search_type == "sales") {
              navigate(`/property-for-sale/${property.slug}-${property.id}`, {
                replace: true,
              })
            }
          }
          else if (property.department == "new_developments") {
            if (property.search_type == "sales") {
              navigate(`/property-for-sale/${property.slug}-${property.id}`, {
                replace: true,
              })
            }
          }
          else if (property.department == "LE") {
            if (property.search_type == "lettings") {
              navigate(`/property-to-rent/${property.slug}-${property.id}`, {
                replace: true,
              })
            }
          }
          else if (property.department == "commercial") {
            if (property.search_type == "sales") {
              navigate(`/commercial-property-for-sale/${property.slug}-${property.id}`, {
                replace: true,
              })
            } else if (property.search_type == "lettings") {
              navigate(`/commercial-property-to-rent/${property.slug}-${property.id}`, {
                replace: true,
              })
            }
          }
          else if (property.department == 'auction_residential') {
            navigate(`/auction-property-for-sale/${property.slug}-${property.id}`, {
              replace: true,
            })
          }
        } else {
          getnewhomesPropurl(setNewPropdata, crm_id[0])
          if(propnewdata){
            let property_newdata = JSON.parse(propnewdata)
            let property_newarr = property_newdata.data.newDevelopments
            if (property_newarr.length > 0) {
              let newproperty = property_newarr[0]
              if(newproperty?.id){
                navigate(`/property-for-sale/${newproperty.slug}-${newproperty.id}`, {
                  replace: true,
                })
              }
              else {
                setShowtemplate(true) // show 404 page template
              }
            }
          }
          else {
            setShowtemplate(true) // show 404 page template
          }
        }
      }
       else {
        setShowtemplate(true) // show 404 page template
      }
    }
  }, [propdata])
  return (
    <div>
      {showtemplate &&
        <Fragment>
          <MarketingServices404 />
        </Fragment>
      }
    </div>
  )
}

export default NotFoundPage