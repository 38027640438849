import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import "./MarketingServices404.scss"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { Contact_Page_Url } from "@Components/common/site/constants"
// Header component

const MarketingServices404 = props => {
  return (
    <React.Fragment>
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.section
            className="marketing-services"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={contentItemStagger}
          >
            <Container>
              <Row className="flex-md-column-reverse flex-lg-row">
                <Col lg="12">
                  <div className="services-content notfound">
                    <motion.h2 variants={contentItem}>
                      We’re sorry, the page you were looking for cannot be
                      found.
                    </motion.h2>
                    <motion.div variants={contentItem}>
                      <p>
                        This is maybe because it has been removed, we've changed
                        its name or it is temporarily unavailable.
                      </p>
                    </motion.div>
                    <motion.div className="d-lg-flex" variants={contentItem}>
                      <Link class="btn" to={"/"}>
                        back to homepage
                      </Link>
                      <Link class="btn outline-btn" to={Contact_Page_Url.alias}>
                        Contact
                      </Link>
                    </motion.div>
                  </div>
                </Col>
              </Row>
            </Container>
          </motion.section>
        )}
      </InView>
    </React.Fragment>
  )
}
export default MarketingServices404
